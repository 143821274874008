import { useRiderComponent } from '@digital-motors-boatyard/by-vessel-rider.component';
import { DealSheetVehicle } from '@digital-motors-boatyard/common/dist/interfaces/entity/dealsheet/DealSheetVehicle.interface';
import { Button } from '@digital-motors-boatyard/ui.button';
import { rgba } from '@digital-motors-boatyard/ui.helpers';
import { ArrowLeft, Close, User } from '@digital-motors-boatyard/ui.icons';
import {
  borderRadius,
  shadows,
  spacing,
  styled,
  unit,
} from '@digital-motors-boatyard/ui.theme';
import { FC, useState } from 'react';

import { RIDER_WIDTH } from '../constants';
import { useAnalytics } from '../context/Analytics';
import { useBoatyardAnalyticsParams } from '../context/Analytics/utils/getParamsFromBoatyard';
import { useUser } from '../context/User';
import { useBoatyard } from '../hooks/useBoatyard';
import { useIsInverted } from '../hooks/useIsInverted';
import { color } from '../lib/color';
import VehicleImage, { Props as VehicleImageProps } from './VehicleImage';

export interface Props {
  infoOnly?: boolean;
  hideCloseButton?: boolean;
  onClickBack?: () => void;
  signInUser: () => void;
}

const HeaderButton = styled(Button)<{ position: 'left' | 'right' }>`
  background: ${(props) => rgba(color('background', 'dark')(props), 0.2)};
  padding: 0;
  border-radius: ${borderRadius.round};
  width: ${spacing.s};
  height: ${spacing.s};
  backdrop-filter: blur(24px);
  position: fixed;
  z-index: 10;
  top: ${spacing.xs};
  ${(props) => props.position}: ${spacing.xs};

  &,
  :hover,
  :focus,
  :visited,
  :active {
    border: 1px solid
      ${(props) => rgba(color('background', 'dark')(props), 0.1)};
  }

  :hover,
  :focus {
    background: ${(props) => rgba(color('background', 'dark')(props), 0.3)};
  }

  @media screen and (min-width: 600px) {
    left: auto;
    right: ${(props) =>
      props.position === 'right' ? unit * 4 : RIDER_WIDTH - 60}px;
  }
`;
HeaderButton.defaultProps = {
  variant: 'clear',
};

const CloseIcon = styled(Close)`
  path {
    stroke: ${color('background')};
    stroke-width: 2.5px;
  }
`;

const ArrowLeftIcon = styled(ArrowLeft)`
  path {
    stroke: ${color('background')};
    stroke-width: 2.5px;
  }
`;

const UserIcon = styled(User)`
  path {
    stroke: ${color('background')};
    stroke-width: 2px;
  }
`;

const UserMenuOverlay = styled.div`
  background: ${color('bg60', 'light')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  width: 100%;

  @media screen and (min-width: 600px) {
    width: ${RIDER_WIDTH}px;
  }
`;

const UserMenu = styled.div`
  background: ${color('background')};
  border-radius: ${borderRadius.s};
  box-shadow: ${shadows.s};
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${spacing.xxxs};
  padding: ${spacing.xxxs};
  width: ${spacing.l};
  position: fixed;
  top: ${spacing.units(14)};
  right: ${spacing.xs};
  z-index: 11;

  button {
    justify-content: flex-end;
  }
`;

export const HeaderDetails: FC<Props & VehicleImageProps> = ({
  infoOnly,
  hideCloseButton,
  onClickBack,
  signInUser,
  ...rest
}) => {
  const isInverted = useIsInverted();
  const { trackEvent } = useAnalytics();
  const analyticsParams = useBoatyardAnalyticsParams();
  const boatyard = useBoatyard();
  const { isRegistered, logoutUser } = useUser();
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  return (
    <>
      {!infoOnly && (
        <>
          {!hideCloseButton && !onClickBack && (
            <HeaderButton
              data-testid="Header__Close"
              aria-label="Close Deal"
              position="left"
              onClick={() => {
                boatyard?.closeRider();
                trackEvent('rider_close', analyticsParams);
              }}
            >
              <CloseIcon width={16} height={16} />
            </HeaderButton>
          )}
          {!!onClickBack && (
            <HeaderButton
              data-testid="Header__Back"
              aria-label="Go Back"
              position="left"
              onClick={onClickBack}
            >
              <ArrowLeftIcon width={16} height={16} />
            </HeaderButton>
          )}
          <HeaderButton
            data-testid="Header__UserMenu"
            position="right"
            aria-label="Open User Menu"
            onClick={
              isRegistered ? () => setUserMenuOpen(true) : () => signInUser()
            }
          >
            <UserIcon width={16} height={16} />
          </HeaderButton>
          {userMenuOpen && (
            <>
              <UserMenuOverlay onClick={() => setUserMenuOpen(false)} />
              <UserMenu>
                <Button
                  data-testid="Header__SignOut"
                  size="s"
                  variant="secondary"
                  inverted={isInverted}
                  onClick={() => {
                    logoutUser();
                    boatyard?.closeRider();
                    trackEvent('rider_signout', analyticsParams);
                  }}
                >
                  Sign Out
                </Button>
                <Button
                  data-testid="Header__Dismiss"
                  size="s"
                  variant="secondary"
                  inverted={isInverted}
                  onClick={() => {
                    boatyard?.closeRider();
                    setUserMenuOpen(false);
                  }}
                >
                  Dismiss
                </Button>
              </UserMenu>
            </>
          )}
        </>
      )}
      <VehicleImage infoOnly={infoOnly} {...rest} />
    </>
  );
};

export const Header: FC<Props> = (props) => {
  const { dealSheet, riderView, setRiderView } = useRiderComponent();
  if (!dealSheet) return null;

  const onClickBack =
    riderView === 'summary' ? () => setRiderView('deal') : undefined;

  return (
    <HeaderDetails
      onClickBack={onClickBack}
      {...(dealSheet.vehicle as DealSheetVehicle)}
      {...props}
    />
  );
};
