"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCountryProperties = exports.COUNTRY_PROPERTIES = void 0;
var enums_1 = require("@digital-motors-boatyard/common/dist/enums");
var formatPostalCode_1 = require("@digital-motors-boatyard/common-frontend/dist/utility/formatPostalCode");
var components_form_1 = require("@digital-motors-boatyard/components.form");
var react_1 = require("@emotion/react");
var react_2 = require("react");
var DealSheet_1 = require("../context/DealSheet");
var keyDownHandler = function (country) { return function (e) {
    if (e.key.length > 1)
        return e;
    var _a = e.currentTarget, value = _a.value, start = _a.selectionStart, end = _a.selectionEnd;
    if (value && start !== null && end !== null && start !== end) {
        e.currentTarget.value = value.replace(value.substring(start, end), '');
        e.currentTarget.selectionStart = start;
        e.currentTarget.selectionEnd = start;
    }
    (0, formatPostalCode_1.keyDownPostalCodeForCountry)(country)(e);
}; };
exports.COUNTRY_PROPERTIES = (_a = {},
    _a[enums_1.Country.UNITED_STATES] = {
        zipLabel: 'ZIP Code',
        zipLength: 5,
        zipValidation: {
            required: components_form_1.required,
            charLength: (0, components_form_1.exactCharacterLength)(5, 'Zip code must be 5 digits'),
            pattern: (0, components_form_1.matchesRegex)(/\d+/g, 'Zip code is not valid'),
        },
        zipInputProps: {
            maxLength: 5,
            onKeyDown: keyDownHandler(enums_1.Country.UNITED_STATES),
            inputMode: 'numeric',
        },
        distanceLabel: 'mi',
        odometerLabel: 'Current Mileage (Odometer)',
        odometerValidation: {
            required: components_form_1.required,
            inNumericRange: (0, components_form_1.inNumericRange)(0, 499999, 'Must be between 0 and 499,999 miles'),
        },
        stateLabel: 'State',
        stateEnum: enums_1.UnitedStatesState,
        ssnLabel: 'Social Security Number',
        ssnLabelAbbv: 'SSN',
    },
    _a[enums_1.Country.CANADA] = {
        zipLabel: 'Postal Code',
        zipLength: 7,
        zipValidation: {
            required: components_form_1.required,
            // length is 7 instead of 6 to allow a formatted space
            charLength: (0, components_form_1.exactCharacterLength)(7, 'Postal code must be 6 characters'),
            pattern: (0, components_form_1.matchesRegex)(/[a-z]\d[a-z]\s?\d[a-z]\d/gi, 'Postal code is not valid'),
        },
        zipInputProps: {
            maxLength: 7,
            onKeyDown: keyDownHandler(enums_1.Country.CANADA),
            css: (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        input {\n          text-transform: uppercase;\n        }\n      "], ["\n        input {\n          text-transform: uppercase;\n        }\n      "]))),
        },
        distanceLabel: 'km',
        odometerLabel: 'Current Kilometers (Odometer)',
        odometerValidation: {
            required: components_form_1.required,
            inNumericRange: (0, components_form_1.inNumericRange)(0, 499999, 'Must be between 0 and 499,999 kilometers'),
        },
        stateLabel: 'Province',
        stateEnum: enums_1.CanadianProvince,
        ssnLabel: 'Social Insurance Number',
        ssnLabelAbbv: 'SIN',
    },
    _a);
var useCountryProperties = function () {
    var dealSheet = (0, DealSheet_1.useDealSheet)().dealSheet;
    var country = dealSheet === null || dealSheet === void 0 ? void 0 : dealSheet.config.country;
    return (0, react_2.useMemo)(function () {
        return exports.COUNTRY_PROPERTIES[country === enums_1.Country.CANADA ? country : enums_1.Country.UNITED_STATES];
    }, [country]);
};
exports.useCountryProperties = useCountryProperties;
var templateObject_1;
