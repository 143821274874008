"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DealBreakdown = void 0;
var formatCurrency_1 = require("@digital-motors-boatyard/common/dist/helpers/formatCurrency");
var isPercent_1 = require("@digital-motors-boatyard/common/dist/helpers/isPercent");
var ui_text_1 = require("@digital-motors-boatyard/ui.text");
var ui_theme_1 = require("@digital-motors-boatyard/ui.theme");
var react_1 = __importStar(require("react"));
var constants_1 = require("../constants");
var DealSheet_1 = require("../context/DealSheet");
var Pricing_1 = require("../context/Pricing");
var DealSheetPricingContext_1 = require("../context/Pricing/DealSheetPricingContext");
var Tenant_1 = require("../context/Tenant");
var color_1 = require("../lib/color");
var Breakdown = ui_theme_1.styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 2px solid ", ";\n  border-radius: ", ";\n  padding: 0 ", " ", ";\n"], ["\n  border: 2px solid ", ";\n  border-radius: ", ";\n  padding: 0 ", " ", ";\n"])), (0, color_1.color)('divider'), ui_theme_1.borderRadius.s, ui_theme_1.spacing.xs, ui_theme_1.spacing.xs);
var List = ui_theme_1.styled.dl(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(2, auto);\n  margin: 0;\n  padding: ", " 0 ", ";\n  border-top: 1px solid ", ";\n\n  :first-of-type {\n    border-top: 0;\n  }\n\n  dd {\n    text-align: right;\n  }\n"], ["\n  display: grid;\n  grid-template-columns: repeat(2, auto);\n  margin: 0;\n  padding: ", " 0 ", ";\n  border-top: 1px solid ", ";\n\n  :first-of-type {\n    border-top: 0;\n  }\n\n  dd {\n    text-align: right;\n  }\n"])), ui_theme_1.spacing.units(3), ui_theme_1.spacing.xs, (0, color_1.color)('divider'));
var Label = (0, ui_theme_1.styled)(ui_text_1.Text)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (props) {
    if (props.variant !== 'small') {
        return "padding: ".concat(ui_theme_1.spacing.xxs, " 0 0;");
    }
    return "\n      color: ".concat((0, color_1.color)('subtle')(props), ";\n      padding: ").concat(ui_theme_1.spacing.xxxs, " 0 0;\n\n      :before {\n        content: '\u2022';\n        padding: 0 ").concat(ui_theme_1.spacing.xxxs, " 0 ").concat(ui_theme_1.spacing.xxs, ";\n      }\n    ");
});
Label.defaultProps = {
    as: 'dt',
};
var Value = (0, ui_theme_1.styled)(ui_text_1.Text)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (props) {
    if (props.variant !== 'small') {
        return "padding: ".concat(ui_theme_1.spacing.xxs, " 0 0;");
    }
    return "\n      color: ".concat((0, color_1.color)('subtle')(props), ";\n      padding: ").concat(ui_theme_1.spacing.xxxs, " 0 0;\n    ");
});
Value.defaultProps = {
    as: 'dd',
};
var Disclaimer = (0, ui_theme_1.styled)(ui_text_1.Text)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), (0, color_1.color)('subtle'));
Disclaimer.defaultProps = {
    variant: 'caption',
};
var DealBreakdown = function () {
    var configuration = (0, Tenant_1.useTenant)().configuration;
    var dealSheet = (0, DealSheet_1.useDealSheet)().dealSheet;
    var vehicle = (0, DealSheet_1.useDealSheetVehicle)().vehicle;
    var _a = vehicle || {}, fniProducts = _a.fniProducts, condition = _a.condition;
    var dealSheetCalculations = (0, Pricing_1.usePricingContext)().dealSheetCalculations;
    var _b = (0, DealSheetPricingContext_1.useDealSheetPricing)().pricing || {}, discounts = _b.discounts, financeType = _b.financeType, totalMsrp = _b.totalMsrp, dmSellingPrice = _b.dmSellingPrice, customModelIncentives = _b.customModelIncentives, retailPrice = _b.retailPrice, dealerFees = _b.dealerFees;
    var tradeInEstimate = dealSheet.tradeInEstimate;
    var dealerDiscount = dealSheetCalculations.dealerDiscount, amountDueAtSigning = dealSheetCalculations.amountDueAtSigning, calculatedDownPayment = dealSheetCalculations.calculatedDownPayment, incentivesSum = dealSheetCalculations.incentivesSum, netTradeInValue = dealSheetCalculations.netTradeInValue, fniProductsSum = dealSheetCalculations.fniProductsSum, totalPrice = dealSheetCalculations.totalPrice, reservationDeposit = dealSheetCalculations.reservationDeposit;
    return (react_1.default.createElement(Breakdown, { "data-testid": "DealBreakdown__Breakdown" },
        condition === constants_1.USED ? (react_1.default.createElement(List, null,
            react_1.default.createElement(Label, { bold: true }, "Retail Price"),
            react_1.default.createElement(Value, { "data-testid": "DealBreakdown__RetailPrice", bold: true }, (0, formatCurrency_1.formatCurrency)(retailPrice || 0)))) : (react_1.default.createElement(List, null,
            react_1.default.createElement(Label, { bold: true }, "Total MSRP"),
            react_1.default.createElement(Value, { "data-testid": "DealBreakdown__TotalMsrp", bold: true }, (0, formatCurrency_1.formatCurrency)(totalMsrp || 0)))),
        react_1.default.createElement(List, null,
            react_1.default.createElement(Label, { bold: true }, "Dealer Price"),
            react_1.default.createElement(Value, { bold: true, "data-testid": "DealBreakdown__DealerPrice" }, (0, formatCurrency_1.formatCurrency)(dmSellingPrice || 0)),
            dealerDiscount > 0 && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(Label, null, "Dealer Discount"),
                react_1.default.createElement(Value, { "data-testid": "DealBreakdown__DealerDiscount" }, (0, formatCurrency_1.formatCurrency)(-dealerDiscount)))),
            dealerDiscount < 0 && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(Label, null, "Added Dealer Markup"),
                react_1.default.createElement(Value, { "data-testid": "DealBreakdown__Markup" }, (0, formatCurrency_1.formatCurrency)(-dealerDiscount))))),
        !!reservationDeposit && (react_1.default.createElement(List, null,
            react_1.default.createElement(Label, { bold: true }, "Reservation"),
            react_1.default.createElement(Value, null),
            react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(Label, null, "Deposit"),
                react_1.default.createElement(Value, { "data-testid": "DealBreakdown__ReservationDeposit" }, (0, formatCurrency_1.formatCurrency)(-reservationDeposit))))),
        react_1.default.createElement(List, null,
            financeType === constants_1.CASH && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(Label, { bold: true }, "Sales Price"),
                react_1.default.createElement(Value, { bold: true, "data-testid": "DealBreakdown__AmountFinance" }, (0, formatCurrency_1.formatCurrency)(totalPrice)))),
            financeType === constants_1.FINANCE && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(Label, { bold: true }, "Amount Financed"),
                react_1.default.createElement(Value, { bold: true, "data-testid": "DealBreakdown__AmountFinance" }, (0, formatCurrency_1.formatCurrency)(totalPrice)),
                react_1.default.createElement(Label, null, "Down Payment"),
                react_1.default.createElement(Value, { "data-testid": "DealBreakdown__CalculateDownPayment" }, (0, formatCurrency_1.formatCurrency)(calculatedDownPayment || 0)))),
            !!incentivesSum && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(Label, null, "Incentives"),
                react_1.default.createElement(Value, { "data-testid": "DealBreakdown__IncentiveSum" }, (0, formatCurrency_1.formatCurrency)(-incentivesSum)),
                __spreadArray(__spreadArray([], (discounts || []), true), (customModelIncentives || []), true).map(function (incentive, idx) {
                    var amount = Number(incentive.amount);
                    var value = (0, isPercent_1.isPercent)(amount)
                        ? "-".concat(amount * 100, "%")
                        : (0, formatCurrency_1.formatCurrency)(-amount);
                    return (react_1.default.createElement(react_1.Fragment, { key: idx },
                        react_1.default.createElement(Label, { variant: "small" }, incentive.name),
                        react_1.default.createElement(Value, { variant: "small", "data-testid": "DealBreakdown__".concat(idx, "__IncentiveAmount") }, value)));
                }))),
            !!(tradeInEstimate === null || tradeInEstimate === void 0 ? void 0 : tradeInEstimate.estimate) && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(Label, null,
                    "Net Trade-In ",
                    netTradeInValue < 0 ? 'Deficit' : 'Credit'),
                react_1.default.createElement(Value, { "data-testid": "DealBreakdown__NetTradeInValue" }, (0, formatCurrency_1.formatCurrency)(netTradeInValue ? -netTradeInValue : 0)),
                react_1.default.createElement(Label, { variant: "small" }, "Trade-In Estimate"),
                react_1.default.createElement(Value, { "data-testid": "DealBreakdown__TradeInEstimate", variant: "small" }, (0, formatCurrency_1.formatCurrency)(-tradeInEstimate.estimate)),
                react_1.default.createElement(Label, { variant: "small" }, "Payoff Amount"),
                react_1.default.createElement(Value, { "data-testid": "DealBreakdown__TradeInPayoffAmount", variant: "small" }, (0, formatCurrency_1.formatCurrency)(tradeInEstimate.payoffAmount || 0)))),
            (configuration === null || configuration === void 0 ? void 0 : configuration.web.vdp.showProtectionProducts) && !!fniProductsSum && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(Label, null, "Protection Plans"),
                react_1.default.createElement(Value, { "data-testid": "DealBreakdown__ProtectionPlanSum" }, (0, formatCurrency_1.formatCurrency)(fniProductsSum)),
                (fniProducts || []).map(function (product) { return (react_1.default.createElement(react_1.Fragment, { key: product.protectionPlanId ||
                        product.id },
                    react_1.default.createElement(Label, { variant: "small" }, product.name),
                    react_1.default.createElement(Value, { variant: "small", "data-testid": "DealBreakdown__".concat(product.protectionPlanId, "__ProtectionAmount") }, (0, formatCurrency_1.formatCurrency)(product.price)))); }))),
            (dealerFees || []).map(function (_a, idx) {
                var name = _a.name, amount = _a.amount;
                return (react_1.default.createElement(react_1.Fragment, { key: "dealerFee_".concat(idx) },
                    react_1.default.createElement(Label, null, name),
                    react_1.default.createElement(Value, { "data-testid": "DealBreakdown__DealerFee__".concat(idx) }, (0, formatCurrency_1.formatCurrency)(Number(amount)))));
            })),
        react_1.default.createElement(List, null,
            react_1.default.createElement(Label, { bold: true }, "Amount Due at Signing"),
            react_1.default.createElement(Value, { bold: true, "data-testid": "DealBreakdown__DueAtSigning" }, (0, formatCurrency_1.formatCurrency)(amountDueAtSigning)),
            financeType === constants_1.CASH && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(Label, null, "Sales Price"),
                react_1.default.createElement(Value, { "data-testid": "DealBreakdown__SalesPrice" }, (0, formatCurrency_1.formatCurrency)(totalPrice)))),
            financeType === constants_1.FINANCE && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(Label, null, "Down Payment"),
                react_1.default.createElement(Value, { "data-testid": "DealBreakdown__DownPayment" }, (0, formatCurrency_1.formatCurrency)(calculatedDownPayment || 0))))),
        react_1.default.createElement(Disclaimer, { "data-testid": "DealBreakdown__Disclaimer" }, "* Excluding taxes, title, and registration fees.")));
};
exports.DealBreakdown = DealBreakdown;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
