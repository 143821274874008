"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TermSelector = void 0;
var constants_1 = require("@digital-motors-boatyard/common-frontend/dist/constants");
var ui_text_1 = require("@digital-motors-boatyard/ui.text");
var ui_theme_1 = require("@digital-motors-boatyard/ui.theme");
var react_1 = __importStar(require("react"));
var Pricing_1 = require("../../context/Pricing");
var DealSheetPricingContext_1 = require("../../context/Pricing/DealSheetPricingContext");
var useIsInverted_1 = require("../../hooks/useIsInverted");
var styles_1 = require("../../styles");
var Label = (0, ui_theme_1.styled)(ui_text_1.Text)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n  margin-bottom: ", ";\n"], ["\n  display: block;\n  margin-bottom: ", ";\n"])), ui_theme_1.spacing.xxs);
Label.defaultProps = {
    as: 'label',
    bold: true,
    variant: 'small',
};
var getGridColumnCount = function (total) {
    if (total < 5) {
        return total;
    }
    if (total < 10 && total % 3 === 0) {
        return 3;
    }
    if (total % 5 === 0) {
        return 5;
    }
    return 4;
};
var TermGrid = ui_theme_1.styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  grid-gap: ", ";\n  grid-template-columns: repeat(\n    ", ",\n    1fr\n  );\n"], ["\n  display: grid;\n  grid-gap: ", ";\n  grid-template-columns: repeat(\n    "
    // @ts-ignore
    , ",\n    1fr\n  );\n"])), ui_theme_1.spacing.xxs, 
// @ts-ignore
function (props) { return getGridColumnCount(props.count); });
var TermSelector = function () {
    var isInverted = (0, useIsInverted_1.useIsInverted)();
    var _a = (0, DealSheetPricingContext_1.useDealSheetPricing)(), pricing = _a.pricing, updateDealSheetPricing = _a.updateDealSheetPricing;
    var _b = pricing || {}, contextCreditRating = _b.creditRating, contextTerm = _b.term;
    var _c = (0, Pricing_1.usePricingContext)(), hasPricingData = _c.hasPricingData, hasRateForTerm = _c.hasRateForTerm, defaultCreditRating = _c.defaultCreditRating;
    var creditRating = contextCreditRating || defaultCreditRating;
    var aprTerms = (0, react_1.useMemo)(function () {
        if (!hasPricingData)
            return [];
        return constants_1.APR_TERMS.filter(function (term) {
            return hasRateForTerm({
                creditRating: creditRating,
                term: term,
            });
        });
    }, [creditRating, hasPricingData, hasRateForTerm]);
    if (!aprTerms.length)
        return null;
    return (react_1.default.createElement("div", null,
        react_1.default.createElement(Label, null, "Monthly Term"),
        react_1.default.createElement(TermGrid, { count: aprTerms.length }, aprTerms.map(function (term) { return (react_1.default.createElement(styles_1.ToggleButton, { key: term, "data-testid": "TermSelector__".concat(term), inverted: isInverted, selected: term === contextTerm, onClick: function () { return updateDealSheetPricing({ term: term }); } },
            react_1.default.createElement(ui_text_1.Text, { "data-testid": "PaymentType__Term", variant: "small", bold: term === contextTerm }, term))); }))));
};
exports.TermSelector = TermSelector;
var templateObject_1, templateObject_2;
