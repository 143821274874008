"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePricingCalculations = void 0;
var limits_1 = require("@digital-motors-boatyard/common/dist/constants/limits");
var enums_1 = require("@digital-motors-boatyard/common/dist/enums");
var pricing_1 = require("@digital-motors-boatyard/common-frontend/dist/utility/pricing");
var math_utilities_1 = require("@digital-motors-boatyard/math-utilities");
var react_1 = require("react");
var constants_1 = require("../../../constants");
var DealSheet_1 = require("../../DealSheet");
var Locked_1 = require("../../Locked");
var DealSheetPricingContext_1 = require("../DealSheetPricingContext");
var usePricingCalculations = function (params) {
    var _a;
    var isLocked = (0, Locked_1.useIsLocked)();
    var dealSheet = (0, DealSheet_1.useDealSheet)().dealSheet;
    var vehicle = (0, DealSheet_1.useDealSheetVehicle)().vehicle;
    var _b = (0, DealSheetPricingContext_1.useDealSheetPricing)(), pricing = _b.pricing, loanParams = _b.loanParams;
    var fniProducts = (vehicle || {}).fniProducts;
    var condition = vehicle === null || vehicle === void 0 ? void 0 : vehicle.condition;
    var reservation = dealSheet.reservation, upgrades = dealSheet.upgrades;
    var _c = pricing || {
        financeType: null,
        totalMsrp: null,
        dmSellingPrice: null,
        retailPrice: null,
    }, discounts = _c.discounts, totalMsrp = _c.totalMsrp, dmSellingPrice = _c.dmSellingPrice, retailPrice = _c.retailPrice, financeType = _c.financeType, dealerFees = _c.dealerFees, customModelIncentives = _c.customModelIncentives;
    var dealSheetPricing = params.dealSheetPricing, hasFinanceData = params.hasFinanceData, _d = params.tradeInEstimate, tradeInEstimate = _d === void 0 ? dealSheet.tradeInEstimate : _d;
    var rebates = (0, react_1.useMemo)(function () { return (dealSheetPricing === null || dealSheetPricing === void 0 ? void 0 : dealSheetPricing.rebates) || []; }, [dealSheetPricing]);
    var lenderRequirements = (dealSheetPricing === null || dealSheetPricing === void 0 ? void 0 : dealSheetPricing.lenderRequirements) || constants_1.DEFAULT_FINANCE_REQUIREMENTS;
    var selectedOffer = !params.excludeSelectedOffer && ((_a = dealSheet.selectedOffer) === null || _a === void 0 ? void 0 : _a.offerId)
        ? dealSheet.selectedOffer
        : null;
    var reservationDeposit = (reservation === null || reservation === void 0 ? void 0 : reservation.status) === enums_1.ReservationTransactionStatus.SUCCESSFUL &&
        reservation.depositAmount
        ? reservation.depositAmount
        : 0;
    // Get sum calculations
    var fniProductsSum = (0, pricing_1.getFniProductsSum)({ fniProducts: fniProducts, financeType: financeType });
    var incentivesSum = (0, pricing_1.getIncentivesSum)({
        discounts: discounts,
        customModelIncentives: customModelIncentives,
        totalMsrp: totalMsrp,
        retailPrice: retailPrice,
        condition: condition,
    });
    var upgradesSum = (0, pricing_1.getUpgradesSum)({ upgrades: upgrades });
    var dealerFeesSum = (0, pricing_1.getDealerFeesSum)({ dealerFees: dealerFees });
    var netTradeInValue = (0, react_1.useMemo)(function () {
        if (!tradeInEstimate && !dealSheet.tradeInEstimate)
            return 0;
        var _a = tradeInEstimate || dealSheet.tradeInEstimate || {}, estimate = _a.estimate, payoffAmount = _a.payoffAmount;
        return (estimate || 0) - (payoffAmount || 0);
    }, [dealSheet.tradeInEstimate, tradeInEstimate]);
    var financeableSum = (0, react_1.useMemo)(function () {
        if (!dmSellingPrice)
            return 0;
        return (0, math_utilities_1.calculateArraySum)([dmSellingPrice, fniProductsSum, dealerFeesSum]);
    }, [dmSellingPrice, dealerFeesSum, fniProductsSum]);
    var dealSheetDownPayment = (0, react_1.useMemo)(function () {
        if (financeType === constants_1.FINANCE && loanParams) {
            if (selectedOffer)
                return selectedOffer.downPayment || 0;
            return loanParams.downPayment || 0;
        }
        return 0;
    }, [loanParams, financeType, selectedOffer]);
    var maxDownPaymentPercent = (0, react_1.useMemo)(function () {
        if (!dmSellingPrice)
            return 0;
        var _a = (lenderRequirements || {}).minimumAmountFinanced, minFinancing = _a === void 0 ? 0 : _a;
        var maxCredits = (0, math_utilities_1.calculateArraySum)([netTradeInValue, incentivesSum]);
        var theoreticalMax = Math.floor(((financeableSum - maxCredits - minFinancing) / dmSellingPrice) * 100);
        return Math.max(0, Math.min(theoreticalMax, limits_1.MAX_DOWN_PAYMENT));
    }, [
        dmSellingPrice,
        financeableSum,
        lenderRequirements,
        netTradeInValue,
        incentivesSum,
    ]);
    var minDownPaymentPercent = (0, react_1.useMemo)(function () {
        if (!dmSellingPrice)
            return 0;
        var _a = (lenderRequirements || {}).maximumAmountFinanced, maxFinancing = _a === void 0 ? 0 : _a;
        var maxCredits = (0, math_utilities_1.calculateArraySum)([netTradeInValue, incentivesSum]);
        var theoreticalMin = Math.ceil(((maxFinancing - financeableSum + maxCredits) / dmSellingPrice) * -100);
        return Math.max(0, theoreticalMin);
    }, [
        dmSellingPrice,
        financeableSum,
        lenderRequirements,
        netTradeInValue,
        incentivesSum,
    ]);
    var calculatedDownPayment = (0, react_1.useMemo)(function () {
        if (!dmSellingPrice)
            return 0;
        return Math.min(Math.floor((maxDownPaymentPercent / 100) * dmSellingPrice), dealSheetDownPayment);
    }, [dealSheetDownPayment, dmSellingPrice, maxDownPaymentPercent]);
    var priceAndFees = (0, react_1.useMemo)(function () {
        return (0, math_utilities_1.preciseNumber)((0, math_utilities_1.calculateArraySum)([
            isLocked(condition) && !selectedOffer ? totalMsrp : dmSellingPrice,
            fniProductsSum,
            dealerFeesSum,
        ]));
    }, [
        condition,
        dmSellingPrice,
        dealerFeesSum,
        fniProductsSum,
        isLocked,
        selectedOffer,
        totalMsrp,
    ]);
    var cashBack = (0, react_1.useMemo)(function () {
        if (!netTradeInValue)
            return 0;
        switch (financeType) {
            case constants_1.FINANCE: {
                var loanCredit = (0, math_utilities_1.calculateArraySum)([
                    netTradeInValue,
                    incentivesSum,
                    calculatedDownPayment,
                ]);
                if (loanCredit > financeableSum) {
                    return Math.max(Math.round(loanCredit - financeableSum), 0);
                }
                return 0;
            }
            case constants_1.CASH:
            default: {
                var cashCredit = (0, math_utilities_1.calculateArraySum)([netTradeInValue, incentivesSum]);
                return cashCredit > priceAndFees
                    ? Math.max(Math.round(cashCredit - priceAndFees), 0)
                    : 0;
            }
        }
    }, [
        calculatedDownPayment,
        financeableSum,
        financeType,
        incentivesSum,
        netTradeInValue,
        priceAndFees,
    ]);
    var applicableTradeInValue = (0, react_1.useMemo)(function () {
        return cashBack <= 0
            ? netTradeInValue
            : Math.round(netTradeInValue - cashBack);
    }, [cashBack, netTradeInValue]);
    var monthlyPayment = (0, react_1.useMemo)(function () {
        if (selectedOffer) {
            return selectedOffer.paymentAmount;
        }
        return (0, pricing_1.getEstimatedMonthlyPayment)({
            dmSellingPrice: dmSellingPrice,
            discounts: __spreadArray(__spreadArray([], (discounts || []).filter(function (discount) {
                return discount.incentiveType == enums_1.IncentiveType.DISCOUNT ||
                    (discount.incentiveType == enums_1.IncentiveType.REBATE &&
                        !rebates.some(function (rebate) { return rebate.id === discount.incentiveId; }));
            }), true), (isLocked(condition) ? [] : rebates), true),
            basePrice: condition === enums_1.Condition.USED ? retailPrice : totalMsrp,
            loan: loanParams || null,
            financeType: financeType,
            fniProducts: fniProducts || [],
            calculatedDownPayment: calculatedDownPayment,
            applicableTradeInValue: applicableTradeInValue,
            dealerFeesSum: dealerFeesSum,
            titleRegistrationFeesTotal: 0,
            taxRate: null,
            lenderRequirements: lenderRequirements,
        });
    }, [
        applicableTradeInValue,
        calculatedDownPayment,
        condition,
        discounts,
        dealerFeesSum,
        dmSellingPrice,
        financeType,
        fniProducts,
        isLocked,
        lenderRequirements,
        loanParams,
        rebates,
        retailPrice,
        selectedOffer,
        totalMsrp,
    ]);
    var salesPrice = (0, react_1.useMemo)(function () {
        if (!dmSellingPrice)
            return null;
        var price = (0, math_utilities_1.calculateSalePrice)({
            dealerPrice: dmSellingPrice,
            netTradeInEstimate: applicableTradeInValue,
            fniProductsSum: fniProductsSum,
            incentivesSum: incentivesSum,
            documentationFee: dealerFeesSum,
            downPayment: calculatedDownPayment,
            registrationFee: 0,
        });
        return Math.max(0, price);
    }, [
        applicableTradeInValue,
        calculatedDownPayment,
        dmSellingPrice,
        dealerFeesSum,
        fniProductsSum,
        incentivesSum,
    ]);
    var totalPrice = (0, react_1.useMemo)(function () {
        var netPrice = (0, math_utilities_1.calculateArraySum)([
            salesPrice || 0,
            -reservationDeposit || 0,
        ]);
        return (selectedOffer === null || selectedOffer === void 0 ? void 0 : selectedOffer.balanceAmount) || netPrice;
    }, [salesPrice, selectedOffer, reservationDeposit]);
    var dealerDiscount = (0, react_1.useMemo)(function () {
        if (condition === constants_1.USED) {
            return (retailPrice || dmSellingPrice || 0) - (dmSellingPrice || 0);
        }
        return (totalMsrp || dmSellingPrice || 0) - (dmSellingPrice || 0);
    }, [condition, dmSellingPrice, retailPrice, totalMsrp]);
    return (0, react_1.useMemo)(function () {
        var amountDueAtSigning = financeType === constants_1.CASH
            ? totalPrice - cashBack
            : calculatedDownPayment - cashBack;
        var taxesAndFeesSum = (0, math_utilities_1.calculateArraySum)([dealerFeesSum]);
        return {
            amountDueAtSigning: Math.max(0, amountDueAtSigning),
            applicableTradeInValue: applicableTradeInValue,
            calculatedDownPayment: financeType === constants_1.FINANCE ? Math.max(0, calculatedDownPayment) : null,
            cashBack: cashBack,
            dealerDiscount: dealerDiscount,
            dealerFeesSum: dealerFeesSum,
            financeEnabled: hasFinanceData &&
                !!maxDownPaymentPercent &&
                minDownPaymentPercent <= maxDownPaymentPercent,
            fniProductsSum: fniProductsSum,
            incentivesSum: incentivesSum,
            maxDownPayment: Math.floor((dmSellingPrice || 0) * (maxDownPaymentPercent / 100)),
            minDownPayment: Math.ceil((dmSellingPrice || 0) * (minDownPaymentPercent / 100)),
            monthlyPayment: monthlyPayment,
            netTradeInValue: netTradeInValue,
            salesPrice: salesPrice,
            taxesAndFeesSum: taxesAndFeesSum,
            totalPrice: totalPrice,
            totalSavings: incentivesSum,
            upgradesSum: upgradesSum,
            reservationDeposit: reservationDeposit,
        };
    }, [
        applicableTradeInValue,
        calculatedDownPayment,
        cashBack,
        dealerDiscount,
        dmSellingPrice,
        dealerFeesSum,
        financeType,
        fniProductsSum,
        hasFinanceData,
        incentivesSum,
        maxDownPaymentPercent,
        minDownPaymentPercent,
        monthlyPayment,
        netTradeInValue,
        salesPrice,
        totalPrice,
        upgradesSum,
        reservationDeposit,
    ]);
};
exports.usePricingCalculations = usePricingCalculations;
