"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInitializePricing = void 0;
var pricing_1 = require("@digital-motors-boatyard/common-frontend/dist/utility/pricing");
var react_1 = require("react");
var constants_1 = require("../constants");
var DealSheet_1 = require("../context/DealSheet");
var Pricing_1 = require("../context/Pricing");
var DealSheetPricingContext_1 = require("../context/Pricing/DealSheetPricingContext");
var Tenant_1 = require("../context/Tenant");
var useInitializePricing = function () {
    var _a;
    var tenant = (0, Tenant_1.useTenant)();
    var _b = (0, Pricing_1.usePricingContext)(), hasPricingData = _b.hasPricingData, getBestMatchingPricingParams = _b.getBestMatchingPricingParams;
    var _c = (0, react_1.useState)(false), isDealInitialized = _c[0], setIsDealInitialized = _c[1];
    var condition = ((_a = (0, DealSheet_1.useDealSheetVehicle)().vehicle) === null || _a === void 0 ? void 0 : _a.condition) || null;
    var _d = (0, DealSheetPricingContext_1.useDealSheetPricing)(), pricing = _d.pricing, updateDealSheetPricing = _d.updateDealSheetPricing;
    var _e = pricing || {}, creditRating = _e.creditRating, creditScoreMax = _e.creditScoreMax, contextTerm = _e.term, contextFinanceType = _e.financeType;
    var financeType = contextFinanceType || (0, pricing_1.getDefaultPaymentType)(tenant, condition);
    var term = financeType === constants_1.FINANCE ? contextTerm : null;
    // Ensure that only an available credit+term combination is used
    (0, react_1.useEffect)(function () {
        if (!hasPricingData || financeType !== constants_1.FINANCE || isDealInitialized) {
            return;
        }
        var params = getBestMatchingPricingParams({
            creditRating: creditRating,
            creditScoreMax: creditScoreMax,
            term: term,
        });
        if (params.creditRating && params.term) {
            if (params.creditRating !== creditRating || params.term !== term) {
                updateDealSheetPricing(params);
            }
        }
        else {
            updateDealSheetPricing({ financeType: constants_1.CASH });
        }
        setIsDealInitialized(true);
    }, [
        creditRating,
        creditScoreMax,
        financeType,
        getBestMatchingPricingParams,
        hasPricingData,
        isDealInitialized,
        term,
        updateDealSheetPricing,
    ]);
    // If the pricing data is fetching and the deal was previouslly initialized,
    // reset the flag so it initializes after pricing data is done fetching
    (0, react_1.useEffect)(function () {
        if (!hasPricingData && isDealInitialized) {
            setIsDealInitialized(false);
            return;
        }
    }, [hasPricingData, isDealInitialized]);
};
exports.useInitializePricing = useInitializePricing;
